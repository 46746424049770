<template>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
  
        <!-- Brand logo-->
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <router-link to="/">
            <img :src="require('@/assets/vagsis_logo.png')" alt="logo" />  
          </router-link><br>
            

         
        </b-link>
       
        <!-- /Brand logo-->
  
        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
        <b-row>
          <b-col lg="12" class=" pl-3">
         <label class="brand-text text-primary pl-2 pt-2" >
            <!-- Vuexyd -->
            <i style="color: rgb(240 178 19);font-size: 1.5rem;font-family: 'Courier New', Courier, monospace;" ><b>Performance tracking system for companies..</b> </i>
          </label>
         </b-col>
           <div class="w-100 d-lg-flex align-items-center justify-content-center  px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
              class="img-login "
            />
          </div>
         
        </b-row>
       

          
        </b-col>
        <!-- /Left Text-->
  
        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
             Kullanıcı Girişi
            </b-card-title>
            <b-card-text class="mb-2">
              Lütfen kendinize tanımlı Email adresi ve Şifre ile giriş yapınız!
            </b-card-text>
  
            <!-- form -->
            <validation-observer ref="loginForm">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="kullanıcı@mail.com"
                    />
                    <small v-if="errors.length > 0" class="text-danger">{{ 'Email adresi zorunludur lütfen geçerli bir mail adresi giriniz' }}</small>
                  </validation-provider>
                </b-form-group>
  
                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Şifre</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <small>Şifremi unuttum?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="errors.length > 0"  class="text-danger">{{ 'Şifre girişi zorunludur' }}</small>
                  </validation-provider>
                </b-form-group>
  
                <!-- checkbox -->
                <!-- <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Beni hatırla
                  </b-form-checkbox>
                </b-form-group> -->
  
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isLoading"
         
                >
                
                  <b-spinner v-if="isLoading"  small />
      Giriş Yap
                </b-button>
              </b-form>
            </validation-observer>
  
            <!-- <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{name:'page-auth-register-v2'}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text> -->
  
            <!-- divider -->
            <!-- <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div> -->
  
            <!-- social buttons -->
            <!-- <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                variant="twitter"
                href="javascript:void(0)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                variant="google"
                href="javascript:void(0)"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                variant="github"
                href="javascript:void(0)"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div> -->
          </b-col>
        </b-col>
      <!-- /Login-->
      </b-row>
    </div>
  </template>
  
  <script>
import useJwt from '@/auth/jwt/useJwt'
  
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import {
    BRow, BCol,BSpinner, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
  } from 'bootstrap-vue'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import store from '@/store/index'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import {Api} from '@/helpers/Api/apiConfig'

  
  export default {
  
    components: {
      BSpinner,
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      VuexyLogo,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        isLoading : false,
        status: '',
        password: '',//'123123',
        userEmail: '',//'oguzuslu35@gmail.com',
        sideImg: require('@/assets/images/pages/login-v2.svg'),
        // validation rulesimport store from '@/store/index'
        required,
        email,
      }
    },
    watch: {
     isLoading(val) {
     
       this.isLoading = val
     }
        
      },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/background-login.jpg')
          return this.sideImg
        }
        return this.sideImg
      },
    },
    methods: {
      // validationForm() {
      //   this.$refs.loginValidation.validate().then(success => {
      //     if (success) {
      //       this.$toast({
      //         component: ToastificationContent,
      //         props: {
      //           title: 'Form Submitted',
      //           icon: 'EditIcon',
      //           variant: 'success',
      //         },
      //       })
      //     }
      //   })
      // },
      login() {
      
   
      this.$refs.loginForm.validate().then(async success => {
        this.isLoading = true
        if (success) {
          // useJwt.login({
          //   email: this.userEmail,

          //   password: this.password,
          // })
          await Api("POST", "User/Login/" ,{
            username: this.userEmail,

            password: this.password,
          }).then(response => {
            console.log("response", response.data.data);
           if(response.data.isSuccess==true){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Giriş İşlemi Basarılı!',
                text: 'Anasayfaya yönlendiriliyor...',
                icon: 'CheckCircleIcon',
                variant: 'success',
                
              },
            })
            const userData  = response.data.data.userInfo
              useJwt.setToken(response.data.data.token)
              useJwt.setRefreshToken(response.data.data.token)
              localStorage.setItem('userData', JSON.stringify(userData))
              //this.$ability.update(userData.isAdmin)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
             // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
              let role = (response.data.data.userInfo.generalRole=="Admin"?'admin':'client')
              let SubRole = (response.data.data.userInfo.departmentUser[0].departmentRole=="DepartmentManager"?'DepartmentManager':'DepartmentWorker')

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
             setTimeout(() => {
               
              this.$router.replace(getHomeRouteForLoggedInUser(role,SubRole))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Hoşgeldiniz ${userData.name || userData.surname}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Başarılı bir şekilde ${role==='admin'? 'Admin':SubRole==='DepartmentManager'?'Departman Yöneticisi':'Kullanıcı'} olarak giriş yaptınız. Kullanıma başlaya bilirsiniz!`,
                    },
                  })
                })
             } , 2000)
           }else{

this.$toast({
  component: ToastificationContent,
  props: {
    title: 'Geçersiz e-posta adresi yada şifre. Lütfen tekrar deneyin.',
    icon: 'AlertCircleIcon',
    variant: 'danger',
  },
})
}
           
            })
            .catch(error => {
              console.log(error);
               this.$refs.loginForm.setErrors('error.response.data.message')
            })
        }
        this.isLoading = false;
      })
     
    },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  .img-login{
    
    opacity: 1;
    width: 100%;

    object-fit: cover;
  
    border-radius: 10px;
    padding: 0px !important;
    box-shadow: 0 0 30px 4px #101010;
  }
  </style>
  